import { HowItWorksProps } from "@models/HowItWorks/HowItWorks.models";
import React from "react";
import { ButtonWrapper, Description, HowItWorksSection, LeftColumnWrapper, NumberColumn, NumberInnerStyle, NumberOuterStyle, RightColumnWrapper, StepCotent, StepTitle, Subtitle, TextWrapper, Title, VerticalDivider } from "./CompHowItWorks.styles";
import { Grid, Responsive } from "semantic-ui-react";
import KpButton from "@elements/KpButton/KpButton";
import theme from "@styles/theme";


class CompHowItWorks extends React.Component<HowItWorksProps,any> {
    constructor(props:HowItWorksProps) {
        super(props);
    }

    render() {
        const step1 = this.props.step_icons[0].url
        const step2 = this.props.step_icons[1].url
        const step3 = this.props.step_icons[2].url
        return(
            <HowItWorksSection darkTheme={this.props.darkTheme}>
                <Grid stackable columns={2}>
                    <LeftColumnWrapper>
                        <Title darkTheme={this.props.darkTheme}>{this.props.title}</Title>            
                        <Subtitle darkTheme={this.props.darkTheme}>{this.props.subtitle}</Subtitle>
                        <Description>{this.props.description}</Description>
                        <Responsive minWidth={theme.responsive.desktop.minWidth}>
                            <ButtonWrapper>
                                <KpButton id='hiw_learn_more' buttonType='primary' link={this.props.learn_more_button_url} color={theme.brand.colors.blue} darkTheme={true}>{this.props.learn_more_button_text}</KpButton>
                            </ButtonWrapper>        
                        </Responsive>
                        <Responsive minWidth={theme.responsive.tablet.minWidth} maxWidth={theme.responsive.tablet.maxWidth}>
                            <ButtonWrapper>
                                <KpButton id='hiw_learn_more' buttonType='primary' link={this.props.learn_more_button_url} color={theme.brand.colors.blue} darkTheme={true}>{this.props.learn_more_button_text}</KpButton>
                            </ButtonWrapper>
                        </Responsive>
                    </LeftColumnWrapper>
                    <RightColumnWrapper>
                        <Grid columns={2}>
                            <NumberColumn>
                                {
                                    this.props.step_icons && this.props.step_icons.length <= 3 &&
                                    <img src={step1} width={51} height={51}/>
                                }                                
                                <Grid.Row>
                                    <Grid.Column width={8}>
                                        <VerticalDivider></VerticalDivider>
                                    </Grid.Column>
                                </Grid.Row>
                                {
                                    this.props.step_icons && this.props.step_icons.length <= 3 &&
                                    <img src={step2} width={51} height={51}/>
                                }  
                                <Grid.Row>
                                    <Grid.Column width={8}>
                                        <VerticalDivider></VerticalDivider>
                                    </Grid.Column>
                                </Grid.Row>
                                {
                                    this.props.step_icons && this.props.step_icons.length <= 3 &&
                                    <img src={step3} width={51} height={51}/>
                                }  
                            </NumberColumn>
                            <Grid.Column width={12}>
                                <div>{this.props.steps.map((item)=>{
                                        return (
                                            <TextWrapper>
                                                <StepTitle>{item.title}</StepTitle>                                                                           
                                                <StepCotent darkTheme={this.props.darkTheme} dangerouslySetInnerHTML={{ __html: item.content}}></StepCotent>
                                            </TextWrapper>
                                        )
                                    })}</div>
                            </Grid.Column>
                        
                        </Grid>
                    </RightColumnWrapper>
                </Grid> 
                
                <Responsive maxWidth={theme.responsive.mobile.maxWidth}>
                    <ButtonWrapper>
                        <KpButton id='hiw_learn_more' buttonType='primary' link={this.props.learn_more_button_url} color={theme.brand.colors.blue}>{this.props.learn_more_button_text}</KpButton>
                    </ButtonWrapper> 
                </Responsive>                
                                 
            </HowItWorksSection>
            
        )
    }
}

export default CompHowItWorks